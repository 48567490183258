<template>
  <div class="f-file_detail absolute-cover z-1 bg-black overflow-hidden">

    <section
      class="f-file_detail-main_container transition-{right} duration-500 absolute inset-0"
      :class="isPanelVisible ? 'sm:right-[350px]' : 'panel-hidden'"
      >
      <div class="z-20 relative flex items-center bg-gradient-to-b from-black/50 to-90% p-1">

        <u-tooltip :text="backButtonText">
          <core-selection-toolbar-button
            dark
            size="lg"
            icon="i-ri-arrow-left-line"
            @click="exitDetails"
          />
        </u-tooltip>

        <ul class="m-0 p-0 list-none flex gap-0.5 flex-1 justify-end">
          <li v-show="canOrderPrint" class="flex items-center">
            <u-tooltip text="Print">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="COMMON_ICONS.print"
                @click="orderPrints"
              />
            </u-tooltip>
          </li>
          <li v-show="file.is.project" class="flex items-center">
            <u-tooltip text="Add to Cart">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="COMMON_ICONS.print"
                @click="addToCart"
              />
            </u-tooltip>
          </li>

          <li v-show="file.is.editableJupiterProject">
            <u-tooltip text="Edit">
              <core-selection-toolbar-button
                dark
                size="lg"
                icon="i-ri-edit-fill"
                @click="editJupiterProject"
              />
            </u-tooltip>
          </li>
          <li v-show="file.is.sharable">
            <u-tooltip text="Share">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="COMMON_ICONS.share"
                @click="shareFile"
              />
            </u-tooltip>
          </li>

          <li v-show="isZoomable && !file.is.errored" class="hidden sm:block">
            <u-tooltip text="Zoom">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="isZoomed ? 'i-ri-zoom-out-line' : 'i-ri-zoom-in-line'"
                @click="zoom()"
              />
            </u-tooltip>
          </li>

          <li v-show="file.is.ownedByCurrentUser">
            <u-tooltip text="Favorite">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="file.favorite ? COMMON_ICONS.favoriteFill : COMMON_ICONS.favoriteLine"
                @click="toggleFavorite"
              />
            </u-tooltip>
          </li>

          <li v-show="canChooseThumbnail">
            <u-tooltip text="Change Preview">
              <core-selection-toolbar-button
                dark
                size="lg"
                icon="i-ri-image-fill"
                @click="setThumbnail"
              />
            </u-tooltip>
          </li>
          <li v-show="file.is.editablePhoto">
            <u-tooltip text="Edit">
              <core-selection-toolbar-button
                dark
                size="lg"
                icon="i-ri-crop-2-fill"
                @click="editPhoto"
              />
            </u-tooltip>
          </li>
          <li v-show="file.is.copyableFromUser && file.user">
            <u-tooltip text="Copy">
              <core-selection-toolbar-button
                dark
                size="lg"
                icon="i-ri-file-copy-2-fill"
                @click="copyFileFromUser"
              />
            </u-tooltip>
          </li>
          <li>
            <u-tooltip :text="isPanelVisible ? 'Hide Info' : 'Show Info'">
              <core-selection-toolbar-button
                dark
                size="lg"
                icon="i-ri-information-2-line"
                @click="togglePanel(true)"
              />
            </u-tooltip>
          </li>
          <li v-if="overflowItems[0].length">
            <u-dropdown class="h-full" :items="overflowItems">
              <core-selection-toolbar-button
                dark
                size="lg"
                :icon="COMMON_ICONS.moreVertical"
              />
            </u-dropdown>
          </li>
        </ul>

      </div>

      <file-detail-stage
        ref="detailStageComp"
        :available-space="stageRectangle"
        :is-loading="isLoading"
        @exit="exitDetails"
        @download="downloadFile"
      />

    </section>

    <section
      class="absolute top-0 bottom-0 right-0 w-full xs:w-[350px] bg-charcoal-800 dark pt-3 px-3.5 overflow-y-auto transition-transform duration-500 z-20 scrollbar-light"
      :class="isPanelVisible ? 'translate-x-0' : 'translate-x-full'"
     >
      <div class="flex gap-2 items-center">
        <span class="text-2xl flex-1 text-white">Info</span>
        <u-button
          color="white"
          variant="ghost"
          :icon="COMMON_ICONS.close"
          @click="togglePanel(true)"
          :ui="{color: {white: {ghost: 'text-white hover:bg-white/15'}}}"
        />
      </div>
      <file-detail-info @file-remove-from-collection="onFileRemovedFromCollection" />
    </section>
  </div>
</template>

<script setup>
  import {useStorage} from '@vueuse/core'
  import {storeToRefs} from 'pinia';
  import debounce from 'lodash.debounce';

  const INFO_PANEL_WIDTH = 350;
  const INFO_PANEL_SHOULD_SHOW_STORAGE_KEY = 'f-file-detail-info-panel-should-show';

  const setDefaultZoomEvent = useEventBus('file-detail-set-default-zoom');
  const updateZoomEvent = useEventBus('file-detail-update-zoom');

  const fileContext = inject('fileContext');

  const filesStore = useFilesStore();
  const {file, files} = storeToRefs(filesStore);

  const sessionStore = useSessionStore();
  const fileActions = useFileActions();
  const buildRoute = useBuildRoute();
  const router = useRouter();
  const route = useRoute();

  const detailStageComp = ref();
  const isLoading = ref(false);

  const isPanelVisibleStored = useStorage(INFO_PANEL_SHOULD_SHOW_STORAGE_KEY, true, sessionStorage);
  const isPanelVisible = ref(isPanelVisibleStored.value !== null ? isPanelVisibleStored.value : true);
  const stageRectangle = ref(null);
  const isDownloadPending = ref(false);

  const backButtonText = computed(() => {
    const fileUserName = file.value.user?.name;

    if (fileContext.contextType === FILE_CONTEXTS.file && fileUserName) {
      return `${fileUserName}'s Profile`;
    }
    return 'Back';
  });

  const userContext = useUserContext({entity: file});

  const canChooseThumbnail = computed(() => file.value.is.editableVideo && file.value.screen_caps_count > 1);
  const canOrderPrint = computed(() => file.value.is.printablePhoto && userContext.isEntityOwner.value);
  const showDownloadAction = computed(() => file.value.user);

  const {width: stageWidth, height: stageHeight} = useElementSize(detailStageComp);
  const {width: windowWidth, height: windowHeight} = useWindowSize();
  const isMobileScreenWidth = computed(() => windowWidth.value < 768);

  const isZoomable = computed(() => !(file.value.is.video || file.value.is.audio));
  const isZoomed = ref(false);

  const isRotatable = computed(() => file.value.is.editablePhoto && !file.value.is.gif);


  function togglePanel(shouldPersist = true) {
    isPanelVisible.value = !isPanelVisible.value;

    if (!isMobileScreenWidth.value) {
      const widthAdjustment = INFO_PANEL_WIDTH * (isPanelVisible.value ? -1 : 1);
      updateStageDimensions({widthAdjustment});
    }

    if (shouldPersist) {
      isPanelVisibleStored.value = isPanelVisible.value;
    }
  }

  function exitDetails() {
    const id = fileContext.contextId;
    const slug = fileContext.contextSlug;
    const userId = fileContext.userId;
    const shareToken = fileContext.shareToken;
    const query = fileContext.query;

    switch (fileContext.contextType) {
      case FILE_CONTEXTS.file:
        return navigateTo(buildRoute.toUserProfile({user: file.value.user}));
      case FILE_CONTEXTS.album:
        return navigateTo(buildRoute.toAlbum({
          album: {slug},
          userId,
          shareToken,
          query
        }));
      case FILE_CONTEXTS.tag:
        return navigateTo(buildRoute.toTag({
          tag: {slug},
          userId,
          shareToken,
          query
        }));
      case FILE_CONTEXTS.person:
        return navigateTo(buildRoute.toPerson({
          person: {slug},
          userId,
          shareToken,
          query
        }));
      case FILE_CONTEXTS.container:
        return navigateTo(buildRoute.toContainer({
          container: {id},
          userId,
          shareToken,
        }));
      case FILE_CONTEXTS.project:
        return navigateTo(buildRoute.toProjects());
      default:
        navigateTo('/gallery');
    }
  }

  function addToCart() {
    useProjectActions().addProjectToCart({project: file.value, ownerId: file.value.user.id});
  }

  async function editPhoto() {
    await useFileActions().editImage({
      file: file.value,
      contextType: fileContext.contextType,
      contextId: fileContext.contextId
    });
  }

  async function editJupiterProject() {
    let editProject = file.value;

    if (file.value.is.purchased) {
      const {projectCopy} = await useProjectActions().copyProject({project: editProject, isEditAsCopy: true});

      if (projectCopy) {
        useFilesStore().prependFiles({files: [projectCopy]});

        editProject = projectCopy;
      }
    }

    navigateTo(`/app/print-designer/${editProject.provider_uid}`, {external: true});
  }

  async function orderPrints() {
    useProjectActions().openFilePrintModal({file: file.value});
  }

  async function toggleFavorite() {
    try {
      await useFileActions().toggleFavorite({file: file.value});
    } catch (e) {
      useErrorToast().add();
    }
  }

  function shareFile() {
    fileActions.share({files: file.value});
  }

  function downloadFile() {
    if (!isDownloadPending.value) {
      isDownloadPending.value = true;
      fileActions.downloadFiles({files: file.value, userId: file.value.user.id});

      setTimeout(() => (isDownloadPending.value = false), 5000);
    }
  }

  function zoom() {
    setDefaultZoomEvent.emit({file: file.value, action: isZoomed.value ? 'zoom-out' : 'zoom-in'})
  }

  function updateZoomState({isZoomed: updatedZoomState}) {
    isZoomed.value = updatedZoomState;
  }

  async function rotate({clockwise} = {}) {
    try {
      const rotationDelta = clockwise ? 90 : -90;
      const updates = {rotation: (file.value.images?.rotation ?? 0) + rotationDelta};
      await filesStore.updateFile({id: file.value.id, updates});
    } catch (e) {
      useErrorToast().add();
    }
  }

  async function setThumbnail() {
    await fileActions.setVideoThumbnail({file: file.value});
    //re-fetch the file
    await filesStore.getFileDetails({files: [file.value], refresh: true, ...fileContext});
  }

  function copyFileFromUser() {
    fileActions.copyFilesToCurrentUserLibrary({files: file.value, userId: fileContext.userId});
  }

  function onFileRemovedFromCollection({fileIndexInStore}) {
    if (files.value.length === 0) {
      return exitDetails();
    }

    const toFile = files.value[Math.max(Math.min(fileIndexInStore, files.value.length - 1), 0)];

    router.push({
      params: {
        file_id: toFile.id
      },
      query: route.query,
      hash: route.hash
    });
  }

  async function deleteFile() {
    const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
    await fileActions.deleteFiles({files: file.value});
    onFileRemovedFromCollection({fileIndexInStore});
  }

  const {modals} = useFModals();

  function escHandler(e) {
    if (!modals.value.length && e.keyCode === 27) {
      exitDetails();
    }
  }

  function updateStageDimensions({widthAdjustment = 0} = {}) {
    stageRectangle.value = Object.assign({
      height: stageHeight.value,
      width: stageWidth.value + widthAdjustment
    });
  }

  const overflowItems = computed(() => [
    [
      isRotatable.value ? {
        label: 'Rotate Left',
        icon: 'i-ri-anticlockwise-2-line',
        click: rotate
      } : null,
      isRotatable.value ? {
        label: 'Rotate Right',
        icon: 'i-ri-clockwise-2-line',
        click: () => rotate({clockwise: true})
      } : null,

      {
        label: 'Download',
        icon: COMMON_ICONS.download,
        click: downloadFile,
        disabled: isDownloadPending.value
      },

      userContext.canUserDelete.value ? {
        label: 'Delete',
        icon: COMMON_ICONS.delete,
        click: deleteFile
      } : null
    ].filter(Boolean)
  ]);


  const handleResize = debounce(async () => {
    await nextTick();
    updateStageDimensions()
    //to fix a bug with slower mobile browsers
    await waitFor(750);
    updateStageDimensions();
  }, 100);

  watch(windowWidth, handleResize, {immediate: true});
  watch(windowHeight, handleResize, {immediate: true});

  onMounted(async () => {
    if (isMobileScreenWidth.value && isPanelVisible.value) { //bootstrap sm screen
      togglePanel(false);
    }

    //note: don't use the defineShortcuts composable for this - order of handler execution causes problems when modals are opened
    document.body.addEventListener('keydown', escHandler);

    //todo: test
    await waitFor(300);
    updateStageDimensions();
    updateZoomEvent.on(updateZoomState);
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener('keydown', escHandler);

    updateZoomEvent.off(updateZoomState);
  });

  watch(file, async () => {
    isZoomed.value = false;
    updateStageDimensions();
  });

</script>
