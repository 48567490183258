<template>
  <div @click="emit('click')" class="absolute z-10 group p-7 top-1/2 -translate-y-1/2 cursor-pointer" :class="isNext ? 'right-0 justify-end' : 'left-0 justify-start'">
    <button class="w-14 h-14 bg-charcoal-900/60 flex-center opacity-0 group-hover:opacity-100 duration-300 rounded-full transition-all">
      <u-icon :name="icon" class="text-white text-4xl" />
    </button>
  </div>
</template>

<script setup>
  const props = defineProps({
    isNext: Boolean
  });

  const emit = defineEmits(['click']);

  const icon = props.isNext ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line';
</script>
