<template>
  <!--note: this whole component is in dark mode from a class of `dark` set on parent-->
  <div class="f-file_detail-info" @keydown.left.right.stop>


    <!--INFO-->
    <section>

      <!--NAME-->
      <div class="my-5">
        <file-detail-info-label>Name</file-detail-info-label>
        <core-editable-label v-model="localFile.name" multiline @update:modelValue="updateFile" :disabled="!isEntityOwner" />
      </div>

      <!--DESCRIPTION-->
      <div class="my-5">
        <file-detail-info-label>Description</file-detail-info-label>
        <core-editable-label
          v-model="localFile.description"
          @update:modelValue="updateFile"
          multiline
          :placeholder="isEntityOwner ? `Add a description` : 'No description added yet.'"
          :disabled="!isEntityOwner"
        />
      </div>

      <!--PROJECT NAME & PRICE-->
      <template v-if="file.is.project && file.product">
        <div class="my-5">
          <file-detail-info-label>Project Type</file-detail-info-label>
          <p class="font-semibold text-sm text-white">{{file.product.name}}</p>
        </div>
        <div class="my-5">
          <file-detail-info-label>Price</file-detail-info-label>
          <p class="font-semibold text-sm text-white"><pricing-product-price-display :base-price="projectPrice" :product-id="file.product.id" /></p>
        </div>
      </template>

      <!--PROJECT SPECIFIFC DISPLAY-->
      <template v-if="file.is.project">
        <div class="my-5">
          <file-detail-info-label>Created</file-detail-info-label>
          <p class="font-semibold text-sm text-white">{{$dayjs(file.uploaded_at || file.created_at).format('LL')}}</p>
        </div>
        <div class="my-5">
          <file-detail-info-label>Created With</file-detail-info-label>
          <p class="font-semibold text-sm text-white">{{file.projectCreatedWith}}</p>
        </div>
        <div class="my-5">
          <file-detail-info-label>Last Ordered</file-detail-info-label>
          <p class="font-semibold text-sm text-white">
            <template v-if="file.ordered_at">{{$dayjs(file.ordered_at).format('LL')}}</template>
            <template v-else>Not Yet Ordered</template>
          </p>
        </div>
      </template>

      <!--TAKEN AT AND UPLOAD DATES-->
      <template v-if="file.is.photo || file.is.video || file.is.audio || file.is.document">
        <file-detail-info-label>
          <template v-if="file.is.document">Created</template>
          <template v-if="file.is.audio">Date Recorded</template>
          <template v-else>Date Taken</template>
        </file-detail-info-label>

        <u-popover
          v-model:open="takenAtDatePickerOpen"
          :disabled="!isEntityOwner"
          :ui="{trigger: `flex py-1 -my-1 items-center justify-between ${isEntityOwner ? 'cursor-pointer bg-gray-900 border-b border-gray-800' : ''} ${isEntityOwner && takenAtDatePickerOpen ? 'border-white/50' : ''}`}"
        >
          <div>
            <p class="font-semibold text-sm text-white">{{$dayjs(file.taken_at).utc().format('LL')}}</p>
            <p class="text-sm text-white">{{$dayjs(file.taken_at).utc().format('dddd')}}, {{$dayjs(file.taken_at).utc().format('h:mm A')}}</p>
          </div>
          <u-icon name="i-ri-calendar-line" class="hidden text-white mr-2" />

          <template #panel>
            <core-date-picker
              :auto-apply="false"
              enable-time-picker
              inline
              show-close
              hide-offset-dates
              size="md"
              timezone="UTC"
              v-model="localFile.taken_at"
              @update:modelValue="updateTakenAt"
            />
          </template>
        </u-popover>

        <div class="my-5">
          <file-detail-info-label>Uploaded</file-detail-info-label>
          <p class="font-semibold text-sm text-white">{{$dayjs(file.uploaded_at || file.created_at).format('LL')}}</p>
          <p class="text-sm text-gray-300">
            {{$dayjs(file.uploaded_at || file.created_at).format('dddd')}} {{$dayjs(file.uploaded_at || file.created_at).format('h:mm A')}}
            <template v-if="file.uploadSourceFriendly"> &bullet; {{file.uploadSourceFriendly}}</template>
          </p>
        </div>
      </template>

      <!--DURATION-->
      <template v-if="file.duration">
        <div class="my-5">
          <file-detail-info-label>Duration</file-detail-info-label>
          <p class="font-semibold text-sm text-white">{{formatDuration(file.duration)}}</p>
        </div>
      </template>

      <!-- FILE SIZE & DIMENSIONS-->
      <div v-if="!file.is.project" class="my-5">
        <file-detail-info-label>
          File Size
          <template #action v-if="showVideoOptimizeButton">
            <u-button
              @click.prevent="optimizeVideo"
              size="2xs"
              variant="soft"
              color="charcoal"
              trailing icon="i-ri-stack-line"
              label="Optimize"
            />
          </template>

        </file-detail-info-label>

        <p class="font-semibold text-sm text-white">
          {{file.file_size_gb}}
          <template v-if="showDimensions">({{file.width}} x {{file.height}})</template>
          <template v-if="showPageCount">({{file.pages_count}} pages)</template>
        </p>
      </div>
    </section>

    <!--PEOPLE-->
    <div class="border-t-2 border-charcoal-900" v-if="isEntityOwner">

      <div class="my-5 relative">

        <div v-if="file.is.faceRecProcessing" class="absolute inset-0 backdrop-blur-[2px] z-10 -m-2 flex-center">
          <div class="text-white drop-shadow-md bg-black/25 p-3 rounded-lg font-semibold flex items-center gap-2">
            <u-icon :name="COMMON_ICONS.loading" class="animate-spin text-2xl" />
            <span>Faces Processing</span>
          </div>
        </div>

        <file-detail-info-label large>
          People
          <template #action>
            <div class="flex gap-2">
              <u-button
                @click.prevent="toggleEditPeople"
                size="2xs"
                variant="soft"
                color="charcoal"
                :icon="isEditingPeople ? 'i-ri-check-line' : 'i-ri-pencil-line'"
                :label="isEditingPeople ? 'Done' : 'Edit'"
                v-if="file.people.length"
              />
            </div>
          </template>
        </file-detail-info-label>

        <transition-group name="f-fade" tag="div" v-if="visiblePeople && visiblePeople.length" class="-m-1.5 grid grid-cols-5 mt-2">
          <div v-for="person in visiblePeople" :key="person.id" class="p-1 rounded hover:bg-white/5 transition-colors">

            <template v-if="isEditingPeople">
              <core-confirm-popover
                v-if="person.isManuallyAdded"
                confirm-text="Are you sure you want to remove this person from this photo?"
                :confirm-method="() => personClick(person)"
              >
                <people-list-item
                  @mouseenter="toggleBoundingBox(person)"
                  @mouseleave="toggleBoundingBox(person, true)"
                  :person="person"
                  chip-color="red"
                  chip-text="−"
                  :ui="{chip: {base: 'dark:text-white font-bold', size: {md: 'text-sm h-4 w-4'}}}"
                  text-size="text-2xs"
                  disable-edit
                />
              </core-confirm-popover>

              <people-rename-merge
                v-else
                :person="person"
                :ui="{wrapper: 'static'}"
                :allow-reassociate-visible="true"
                :allow-remove="true"
                :disassociate-method="disassociatePerson"
                @updated="onAfterReAssociated"
                @remove="personClick(person)"
              >
                <people-list-item
                  @mouseenter="toggleBoundingBox(person)"
                  @mouseleave="toggleBoundingBox(person, true)"
                  :person="person"
                  chip-color="red"
                  chip-text="−"
                  :ui="{chip: {base: 'dark:text-white font-bold', size: {md: 'text-sm h-4 w-4'}}}"
                  text-size="text-2xs"
                  disable-edit
                />
              </people-rename-merge>
            </template>

            <people-list-item
              v-else
              @mouseenter="toggleBoundingBox(person)"
              @mouseleave="toggleBoundingBox(person, true)"
              @item-click="personClick(person)"
              :person="person"
              :ui="{chip: {base: 'dark:text-white font-bold', size: {md: 'text-sm h-4 w-4'}}}"
              text-size="text-2xs"
              :disable-edit="Boolean(person.name)"
              :rename-only="Boolean(!person.name)"
            />

          </div>
        </transition-group>

        <div v-else>
          <p class="text-gray-700 italic text-sm">No people added yet.</p>
        </div>

        <div class="mt-4" v-if="otherPeople?.length">
          <p class="text-gray text-xs mb-2 text-center cursor-pointer select-none" @click="toggleEditPeople">{{otherPeople.length}} face{{otherPeople.length > 1 ? 's' : ''}} available to add</p>

          <transition-group name="f-fade" tag="div" v-if="isEditingPeople" class="-m-1.5 grid grid-cols-5">
            <div v-for="(person, i) in otherPeople" :key="person.id || person.person_file_id || i" class="hover:bg-white/5 transition-colors rounded p-1">

              <core-confirm-popover
                v-if="person.isHidden"
                confirm-text="This face is associated with a person you have hidden. Make this person visible everywhere?"
                :confirm-method="() => personClick(person)"
              >
                <people-list-item
                  @mouseenter="toggleBoundingBox(person)"
                  @mouseleave="toggleBoundingBox(person)"
                  :person="person"
                  hide-name
                  disable-edit
                />
              </core-confirm-popover>

              <!-- unqualified || disassociated -->
              <people-rename-merge
                v-else
                :person="person"
                :confirm-merge="false"
                @updated="onPeopleUpdated"
                :ui="{wrapper: 'static'}"
              >
                <people-list-item
                  @mouseenter="toggleBoundingBox(person)"
                  @mouseleave="toggleBoundingBox(person)"
                  :person="person"
                  chip-color="green"
                  chip-text="+"
                  :ui="{chip: {base: 'dark:text-white font-bold', size: {md: 'text-sm h-4 w-4'}}}"
                  hide-name
                  disable-edit
                />
              </people-rename-merge>
            </div>
          </transition-group>
        </div>
      </div>
    </div>

    <!--TAGS-->
    <section class="border-t-2 border-charcoal-900">
      <div class="my-5">
        <file-detail-info-label large>
          Tags

          <template #action>
            <tag-select-menu v-if="isEntityOwner" v-model="localTags" :show-selection-tokens="false" @close="updateTags" :ui="{wrapper: 'static'}">

              <u-button
                size="2xs"
                variant="soft"
                color="charcoal"
                :icon="COMMON_ICONS.add"
                label="Add"
              />
            </tag-select-menu>
          </template>

        </file-detail-info-label>

        <transition-group tag="div" name="f-fade" class="flex gap-1 flex-wrap" v-if="localTags && localTags.length">
          <span v-for="tag in localTags" :key="tag.id || tag.name" class="max-w-full">

            <u-badge size="md" variant="solid" :ui="{base: 'gap-1.5 max-w-full'}" :class="{'cursor-pointer': isEntityOwner}" @click="tagClick(tag)" color="white">
              <tag-avatar :tag="tag" size="xs" />
              <span class="font-semibold flex-1 break-long-string">{{tag.name}}</span>
              <u-icon role="button" :name="COMMON_ICONS.close" v-if="isEntityOwner" class="text-sm p-2" title="Remove tag" @click.stop="removeTag(tag)"/>
            </u-badge>

          </span>
        </transition-group>

        <div v-else>
          <p class="text-gray-700 italic text-sm">No tags added yet.</p>
        </div>
      </div>
    </section>

    <!--ALBUMS-->
    <section class="border-t-2 border-charcoal-900" v-if="isEntityOwner">
      <div class="my-5">
        <file-detail-info-label large>
          Albums

          <template #action>
            <album-select-menu v-if="isEntityOwner" v-model="localAlbums" :show-selection-tokens="false" @close="updateAlbums" :ui="{wrapper: 'static'}">
              <u-button
                size="2xs"
                variant="soft"
                color="charcoal"
                label="Add"
                :icon="COMMON_ICONS.add"
              />
            </album-select-menu>
          </template>
        </file-detail-info-label>

        <transition-group name="f-fade" tag="div" v-if="localAlbums && localAlbums.length" class="md:max-h-52 md:overflow-y-auto md:overflow-x-hidden md:-mr-1.5 sm:pr-1 scrollbar-light flex flex-col gap-2">

          <div v-for="album in localAlbums" :key="album.id || album.name" class="flex gap-3 items-center">
            <div class="flex gap-3 flex-1 items-center" role="button" @click.stop="albumClick(album)">
              <album-avatar :album="album" size="md" />
              <h4 :title="album.name" class="text-white font-semibold text-sm flex-1 break-long-string">{{album.name}}</h4>
            </div>

              <u-dropdown :popper="{placement: 'left'}" :items="[[
                {label: 'Set as Album Cover', icon: COMMON_ICONS.makeCover, click: () => setAlbumCover(album)},
                {label: 'Remove from Album', icon: 'i-ri-close-circle-line', click: () => removeFromAlbum(album)}
              ]]">
                <u-button :icon="COMMON_ICONS.moreVertical" variant="ghost" size="sm" color="white" />
              </u-dropdown>
          </div>
        </transition-group>

        <div v-else>
          <p class="text-gray-700 italic text-sm">Not in any albums yet.</p>
        </div>

      </div>
    </section>

    <!--AVATARS-->
    <section class="border-t-2 border-charcoal-900">
      <!--OWNER AVATAR-->
      <div v-if="file.user" class="my-5">
        <file-detail-info-label>Owner</file-detail-info-label>
        <div class="flex items-center gap-3">

          <div class="flex items-center gap-3 flex-1" role="button" @click.stop="userClick(file.user)">
            <user-avatar :user="file.user" size="lg" />
            <h4 class="font-semibold text-gray-300 break-long-string">{{file.user.name}}</h4>
          </div>

          <u-dropdown v-if="isEntityOwner" :popper="{placement: 'left'}" :items="[[
            {label: 'Set this as Profile Photo', icon: COMMON_ICONS.makeCover, click: () => makeProfilePhoto()}
          ]]">
            <u-button :icon="COMMON_ICONS.moreVertical" variant="ghost" size="sm" color="white" />
          </u-dropdown>
        </div>
      </div>

      <!--UPLOADER AVATAR-->
      <div v-if="hasUploaderAvatar" class="my-5">
        <file-detail-info-label>Uploaded by</file-detail-info-label>
        <div class="flex items-center gap-3">
          <user-avatar :user="file.uploaded_by" size="lg" />
          <h4 class="font-semibold text-gray-300 break-long-string">{{file.uploaded_by.name}}</h4>
        </div>
      </div>
    </section>

  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';
  import cloneDeep from 'lodash.clonedeep';

  const fileContext = inject('fileContext');
  const emit = defineEmits(['file-remove-from-collection']);

  const filesStore = useFilesStore();
  const {file, files} = storeToRefs(filesStore);
  const localFile = ref(cloneDeep(file.value));

  const peopleStore = usePeopleStore();
  const buildRoute = useBuildRoute();

  const isEntityOwner = computed(() => useUserContext({entity: file.value}).isEntityOwner.value);
  const showVideoOptimizeButton = computed(() => file.value.optimizable && file.value.status === STATUSES.finished && file.value.is.ownedByCurrentUser);
  const showDimensions = computed(() => (file.value.is.photo || file.value.is.video) && file.value.width && file.value.height);
  const showPageCount = computed(() => file.value.is.document && file.value.pages_count && (file.value.pages_count > 1));
  const takenAtDatePickerOpen = ref(false);

  const projectPrice = computed(() => {
    if (file.value.is.project) {
      const tc = file.value.total_cents;
      return (typeof tc === 'number')
        ? tc
        : file.value.product && file.value.product.price_cents;
    }
  });

  function refetchFileDetails() {
    return filesStore.getFileDetails({files: [file.value], refresh: true, ...fileContext});
  }

  function updateTakenAt() {
    updateFile();
    takenAtDatePickerOpen.value = false;
  }

  async function updateFile() {
    try {
      if (!localFile.value.name) {
        await nextTick();
        localFile.value.name = file.value.name;
      } else {
        await useFilesStore().updateFile({id: file.value.id, updates: localFile.value});
      }
    } catch (e) {
      useErrorToast().add();
    }
  }

  //video
  async function optimizeVideo() {
    await useFileActions().optimizeVideo({file: file.value});
    //re-fetch the file
    await refetchFileDetails();
  }

  //tags
  const localTags = ref(localFile.value.tags);

  function tagClick(tag) {
    if (isEntityOwner.value) {
      navigateTo(buildRoute.toTag({tag}));
    }
  }

  async function removeTag(tag) {
    try {
      const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
      await useFilesStore().removeFilesFromContext({ids: [file.value.id], contextType: FILE_CONTEXTS.tag, contextId: tag.id})

      if (fileContext.contextType === FILE_CONTEXTS.tag && tag.id === fileContext.contextId) {
        emit('file-remove-from-collection', {fileIndexInStore});
      } else {
        //re-fetch the file
        await refetchFileDetails();
      }

    } catch (e) {
      useErrorToast().add();
    }
  }

  async function updateTags() {
    try {
      const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
      const localTagIds = localTags.value.map(tag => tag.id);
      const fileTagIds = file.value.tags.map(tag => tag.id);
      const tagsToRemove = file.value.tags.filter(tag => !localTagIds.includes(tag.id));
      const tagsToAdd = localTags.value.filter(tag => !fileTagIds.includes(tag.id));

      const requests = tagsToRemove.map(tag => useFilesStore().removeFilesFromContext({ids: [file.value.id], contextType: FILE_CONTEXTS.tag, contextId: tag.id}));

      requests.push(
        useFileApi().updateFiles({ids: [file.value.id], updates: {tagNames: tagsToAdd.map(tag => tag.name)}})
      );

      await Promise.all(requests);

      //we don't need to await the requests for updating tag contexts
      tagsToAdd.map(tag => filesStore.updateContext({contextId: tag.id, contextType: FILE_CONTEXTS.tag}));

      filesStore.updateNegativelyFilteredFiles({ids: [file.value.id], contextType: FILE_CONTEXTS.tag});

      if (fileContext.contextType === FILE_CONTEXTS.tag && !localTagIds.includes(fileContext.contextId)) {
        emit('file-remove-from-collection', {fileIndexInStore});
      } else {
        //re-fetch the file
        await refetchFileDetails();
      }

      useEventBus(TAG_EVENTS.navigationRefresh).emit();
    } catch (e) {
      useErrorToast().add();
    }
  }

  //albums
  const localAlbums = ref(localFile.value.albums);

  function albumClick(album) {
    navigateTo(buildRoute.toAlbum({album}));
  }

  async function setAlbumCover(album) {
    await useFileActions().setFileAsAlbumCover({
      file: file.value,
      album
    });
  }

  async function removeFromAlbum(album) {
    //todo: when removing an album and we are in the same context AND this is the last item in that album, we need to exit
    try {
      const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
      await useFilesStore().removeFilesFromContext({ids: [file.value.id], contextType: FILE_CONTEXTS.album, contextId: album.id})

      if (fileContext.contextType === FILE_CONTEXTS.album && album.id === fileContext.contextId) {
        emit('file-remove-from-collection', {fileIndexInStore});
      } else {
        //re-fetch the file
        await refetchFileDetails();
      }

    } catch (e) {
      useErrorToast().add();
    }
  }

  async function updateAlbums() {
    try {
      const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
      const localAlbumIds = localAlbums.value.map(album => album.id);
      const fileAlbumIds = file.value.albums.map(album => album.id);
      const albumsToRemove = file.value.albums.filter(album => !localAlbumIds.includes(album.id));
      const albumsToAdd = localAlbums.value.filter(album => !fileAlbumIds.includes(album.id));

      const requests = albumsToRemove.map(album => useFilesStore().removeFilesFromContext({ids: [file.value.id], contextType: FILE_CONTEXTS.album, contextId: album.id}));

      requests.push(
        useFileApi().updateFiles({ids: [file.value.id], updates: {albumIds: albumsToAdd.map(album => album.id)}})
      );

      await Promise.all(requests);

      //we don't need to await the requests for updating album contexts
      albumsToAdd.map(album => filesStore.updateContext({contextId: album.id, contextType: FILE_CONTEXTS.album}));

      filesStore.updateNegativelyFilteredFiles({ids: [file.value.id], contextType: FILE_CONTEXTS.tag});

      if (fileContext.contextType === FILE_CONTEXTS.album && !localAlbumIds.includes(fileContext.contextId)) {
        emit('file-remove-from-collection', {fileIndexInStore});
      } else {
        //re-fetch the file
        await refetchFileDetails();
      }

      useEventBus(ALBUM_EVENTS.navigationRefresh).emit();

    } catch (e) {
      useErrorToast().add();
    }
  }

  //avatars
  const hasUploaderAvatar = computed(() => file.value.uploaded_by && file.value.uploaded_by.slug !== file.value.user.slug);

  function userClick(user) {
    navigateTo(buildRoute.toUserProfile({user}));
  }

  async function makeProfilePhoto() {
    await useFileActions().setFileAsProfilePhoto({file: file.value});
  }

  //PEOPLE
  const visiblePeople =  computed(() => file.value.people
    ?.filter(p => p.isVisible)
    .sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    })
    .map(p => Object.assign({}, p, {isManuallyAdded: !p.boundingBox}))
  );

  const otherPeople =  computed(() => file.value.people?.filter(p => !p.isVisible).sort((a, b) => {
    if (a.isHidden) return 1;
    if (b.isHidden) return -1;
    return 0;
  }));

  //reassociate person
  const reassociationTracker = {
    fileIndexInStore: null,
    personId: null
  };

  async function disassociatePerson(person) {
    reassociationTracker.fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);
    reassociationTracker.personId = person.id;

    return await filesStore.removeFilesFromContext({
      ids: [file.value.id],
      contextType: FILE_CONTEXTS.person,
      contextId: person.id
    });
  }

  async function onAfterReAssociated() {
    if (fileContext.contextType === FILE_CONTEXTS.person && reassociationTracker.personId === fileContext.contextId) {
      emit('file-remove-from-collection', {fileIndexInStore: reassociationTracker.fileIndexInStore});
    } else {
      refetchFileDetails();
    }

    onPeopleUpdated();

    reassociationTracker.fileIndexInStore = null;
    reassociationTracker.personId = null;
  }

  //person management
  async function personClick(person) {
    if (isEditingPeople.value) {
      try{
        if (person.isVisible) {
          const fileIndexInStore = files.value.findIndex(sf => sf.id === file.value.id);

          await filesStore.removeFilesFromContext({
            ids: [file.value.id],
            contextType: FILE_CONTEXTS.person,
            contextId: person.id
          });

          if (fileContext.contextType === FILE_CONTEXTS.person && person.id === fileContext.contextId) {
            emit('file-remove-from-collection', {fileIndexInStore});

            return;
          }

        } else if (person.isHidden) {
          await peopleStore.updatePerson({
            id: person.id,
            updates: {
              display_status: PEOPLE_DISPLAY_STATUS.visible
            }
          });
        }

        onPeopleUpdated();

      } catch (e) {
        useErrorToast().add();
      }
    } else {
      navigateTo(buildRoute.toPerson({person}));
    }
  }

  function onPeopleUpdated() {
    useEventBus(PEOPLE_EVENTS.navigationRefresh).emit();
    refetchFileDetails();
  }

  const isEditingPeople = ref(false);
  function toggleEditPeople() {
    isEditingPeople.value = !isEditingPeople.value;
    boundingBoxToggleAllEvent.emit();
  }

  let boundingBoxToggleEvent = useEventBus(`core-face-bounding-box-toggle-${localFile.value.id}`);
  let boundingBoxToggleAllEvent = useEventBus(`core-face-bounding-box-toggle-all-${localFile.value.id}`);

  function toggleBoundingBox(person, forceOff) {
    boundingBoxToggleEvent.emit({
      personFileId: person.person_file_id,
      forceOff
    });
  }

  //interactions
  function disableEditing() {
    isEditingPeople.value = false
  }

  watch(file, (newVal, oldVal) => {
    localFile.value = cloneDeep(newVal);
    localTags.value = localFile.value.tags;
    localAlbums.value = localFile.value.albums;

    if (newVal.id !== oldVal.id) {
      //clear old events
      boundingBoxToggleEvent.off();
      boundingBoxToggleAllEvent.off();

      boundingBoxToggleEvent = useEventBus(`core-face-bounding-box-toggle-${localFile.value.id}`);
      boundingBoxToggleAllEvent = useEventBus(`core-face-bounding-box-toggle-all-${localFile.value.id}`);

      //reset state
      disableEditing();
    }
  });
</script>
